import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';
import TopRibbon from '../../containers/Hosting/TopRibbon';

import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import ContactSection2 from '../../containers/Hosting/Contact2/Misc';
import MiscWrapper from './misc.style';
import { useEffect, useState } from 'react';

const MiscSection1 = ({
  name,
  sectionWrapper,
  row,
  col,
  col2,
  title,
  description,
}) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    const loc = window.location.pathname;
    if (loc.substr(loc.length - 1) === '/') {
      setUrl(loc.substring(0, loc.length - 1));
    } else {
      setUrl(loc);
    }
  }, []);
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Pocket Resources | Reference Sheet for Picking the Right Intelligent Automation Technology"
          description="Reference Sheet for Picking the Right Intelligent Automation Technology"
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="CaseStudyPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MiscWrapper>
            <Box {...sectionWrapper}>
              <Container>
                <Box {...row}>
                  <Box {...col} className="text_content">
                    <Fade left cascade>
                      <Box>
                        <Heading
                          {...title}
                          content="Reference Sheet for Picking the Right Intelligent Automation Technology"
                        />
                        <Text
                          {...description}
                          content="
                          Are you considering Intelligent Automation for your business use cases? While intelligent automation can unlock a multitude of benefits such as eliminating manual effort, boosting operational efficiency, and reducing costs, there are a few technologies to consider."
                        />
                        <Text
                          {...description}
                          content="Perhaps you are wondering whether you need to leverage Artificial Intelligence (AI), Robotic Process Automation (RPA), or Intelligent Process Automation (IPA) that uses both AI and RPA."
                        />
                        <Text
                          {...description}
                          content="What fits your automation requirement? Don’t just spin the wheel and pick at random. Matching the right technology to the type of tasks in your use case will provide maximum value and efficiency, ensuring that your use case is a success."
                        />
                        <Text
                          {...description}
                          content="With RAP’s convenient reference sheet, you’ll be able to self-evaluate your use cases and pick the right technology for the job. "
                        />
                      </Box>
                    </Fade>
                  </Box>
                  <Box {...col}>
                    <ContactSection2 name={url} />
                  </Box>
                </Box>
              </Container>
            </Box>
          </MiscWrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

MiscSection1.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

MiscSection1.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '100px',
    pb: ['0', '0', '50px', '50px', '50px'],
    id: 'misc_section',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49.99%', '49.99%'],
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '35px', '35px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '30px',
    textAlign: 'left',
    className: 'heading',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
};

export default MiscSection1;
